import React, { useState } from "react";
import Login from "./screen/auth/Login";
import { Routes, Route } from "react-router-dom";
import "../src/assets/css/desktop.css";
import Dashboard from "./screen/Dashboard/Dashboard";
import "bootstrap/dist/css/bootstrap.min.css";
import CommentPage from "./screen/contentManagement/comment-page";
import Toc from "./screen/courses/toc";

const App = () => {
  const [show, setShow] = useState();
  return (
    <>
      <Routes>
        <Route path="/" element={<Login setShow={setShow} />} />
        <Route path="/dashboard/*" element={<Dashboard setShow={setShow} />} />
        <Route
          path="/comment-page/:userId/:question"
          element={<CommentPage setShow={setShow} />}
        />
        <Route path="/toc/:id/:subject" element={<Toc setShow={setShow} />} />
      </Routes>
    </>
  );
};

export default App;
