import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Badge from "react-bootstrap/Badge";
import Table from "react-bootstrap/Table";
import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";
import { AiOutlineClose } from "react-icons/ai";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import Paid from "../modal/Paid";

const UserPost = () => {
  const [users, setUsers] = useState([]);
  const [totalCounts, setTotalCounts] = useState("");
  const [paidShow, setPaidShow] = useState(false);
  const [status, setStatus] = useState();
  const navigate = useNavigate();
  const userToken = localStorage.getItem("userToken");
  const handleDelete = (item) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this item?"
    );

    if (!confirmed) {
      // If user clicked Cancel, exit the function
      return;
    }
    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `https://api.multigrad.in/auth/post/${item}`,
      headers: {
        Authorization: userToken,
      },
    };

    axios
      .request(config)
      .then((response) => {
        fetchData();
      })
      .catch((error) => {
        // Handle error scenarios, if needed
      });
  };

  const fetchData = async (action) => {
    try {
      const response = await axios.get(
        "https://api.multigrad.in/auth/panel/all-posts",
        {
          headers: {
            Authorization: userToken,
            "Content-Type": "application/json",
          },
        }
      );

      setTotalCounts(response.data.totalCounts);
      setUsers(response.data.data);
    } catch (error) {}
  };

  useEffect(() => {
    fetchData("approve");
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const usersPerPage = 5; // Number of users to display per page

  // Calculate the index of the first and last user to be displayed on the current page
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    // Go to the previous page
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleCmt = (userId, question) => {
    navigate(`/comment-page/${userId}/${question}`);
  };
  function truncateString(str, numWords) {
    const words = str.split(" ");
    if (words.length > numWords) {
      return words.slice(0, numWords).join(" ") + " ...";
    }
    return str;
  }
  function limitWords(text, maxWords) {
    const words = text.split(" ");
    if (words.length > maxWords) {
      return words.slice(0, maxWords).join(" ") + "...";
    }
    return text;
  }
  return (
    // klmkelke
    <>
      {paidShow && <Paid paidShow={paidShow} setPaidShow={setPaidShow} />}
      <div className="right_sidebar">
        <div className="rs_in_third card border-0">
          <h2 id="total">Total Listed Posts {users.length}</h2>
          <table>
            <thead>
              <tr>
                <th>SR No</th>
                <th>Question</th>
                <th>Answer</th>
                <th>Image</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody id="single">
              {currentUsers.map((user, i) => (
                <tr key={user.id}>
                  <td>{i + 1 + (currentPage - 1) * usersPerPage}</td>
                  <td
                    onClick={handleCmt.bind(null, user._id, user.postQuestion)}
                  >
                    <span className="truncate-td">
                      {truncateString(user.postQuestion, 10)}
                    </span>
                  </td>
                  <td>{limitWords(user.postAnswer, 10)}</td>

                  <td>
                    <img src={user.postImage} width="50px" alt="" />
                  </td>
                  <td>
                    <button onClick={() => handleDelete(user._id)}>
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="pages">
            <button onClick={handlePrevPage} disabled={currentPage === 1}>
              Prev
            </button>
            <button
              onClick={handleNextPage}
              disabled={indexOfLastUser >= users.length}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserPost;
