import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";

const Hackathons = () => {
  const userToken = localStorage.getItem("userToken");
  const [formItems, setFormItems] = useState([{ title: "", subject: "" }]);
  const [formData, setFormData] = useState({
    audienceType: "college",
    classOrCourse: "",
    stream: "",
    subject: "",
    tableOfContent: formItems,
    coursePrivacy: "public",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://api.multigrad.in/auth/course",
      headers: {
        Authorization: userToken,
        "Content-Type": "application/json",
      },
      data: formData,
    };

    axios
      .request(config)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Sended Successfully",
          showConfirmButton: false,
          timer: 1500,
        });
        window.location.reload();
      })
      .catch((error) => {});
  };

  const handleInputChange = (index, field, value) => {
    const updatedFormItems = [...formItems];
    updatedFormItems[index][field] = value;
    setFormItems(updatedFormItems);
  };

  const handleAddItem = () => {
    setFormItems([
      ...formItems,
      { title: "", content: "", contentType: "", contentUrl: "" },
    ]);
  };

  const handleRemoveItem = (index) => {
    const updatedFormItems = formItems.filter((item, i) => i !== index);
    setFormItems(updatedFormItems);
  };

  return (
    // klmkelke
    <>
      <div className="right_sidebar">
        <div className="rs_in_third card border-0">
          <label>Audience Type: </label>
          <select
            name="audienceType"
            value={formData.audienceType}
            onChange={handleChange}
          >
            <option value="college">College</option>
          </select>

          <label>
            Class or Course:
            <select
              name="classOrCourse"
              value={formData.classOrCourse}
              onChange={handleChange}
            >
              <option>Not Listed</option>
              <option>Bachelor of Technology (B.Tech.)</option>
              <option>Bachelor of Medicine, Bachelor of Surgery (MBBS)</option>
              <option>Bachelor of Business Administration (BBA)</option>
              <option>Bachelor of Commerce (B.Com.)</option>
              <option>Bachelor of Science (B.Sc.)</option>
              <option>Bachelor of Arts (B.A.)</option>
              <option>Bachelor of Pharmacy (B.Pharm)</option>
              <option>Bachelor of Design (B.Des.)</option>
              <option>Bachelor of Architecture (B.Arch.)</option>
              <option>Bachelor of Laws (LLB)</option>
              <option>Bachelor of Computer Applications (BCA)</option>
              <option>Bachelor of Dental Surgery (BDS)</option>
              <option>Bachelor of Fine Arts (BFA)</option>
              <option>Bachelor of Education (B.Ed.)</option>
              <option>Bachelor of Hotel Management (BHM)</option>
              <option>
                Bachelor of Journalism and Mass Communication (BJMC)
              </option>
              <option>Bachelor of Physiotherapy (BPT)</option>
              <option>Bachelor of Fashion Design (BFD)</option>
              <option>Bachelor of Science in Nursing (B.Sc. Nursing)</option>
              <option>Bachelor of Social Work (BSW)</option>
              <option>Bachelor of Ayurvedic Medicine and Surgery (BAMS)</option>
              <option>Bachelor of Occupational Therapy (BOT)</option>
              <option>Bachelor of Business Management (BBM)</option>
              <option>Bachelor of Veterinary Science (BVSc)</option>
              <option>Bachelor of Event Management (BEM)</option>
              <option>Bachelor of Animation (B.Anim)</option>
              <option>Master of Technology (M.Tech.)</option>
              <option>Master of Business Administration (MBA)</option>
              <option>Master of Science (M.Sc.)</option>
              <option>Master of Arts (M.A.)</option>
              <option>Master of Pharmacy (M.Pharm)</option>
              <option>Master of Design (M.Des.)</option>
              <option>Master of Architecture (M.Arch.)</option>
              <option>Master of Laws (LLM)</option>
              <option>Master of Computer Applications (MCA)</option>
              <option>Master of Dental Surgery (MDS)</option>
              <option>Master of Fine Arts (MFA)</option>
              <option>Master of Education (M.Ed.)</option>
              <option>Master of Hotel Management (MHM)</option>
              <option>
                Master of Journalism and Mass Communication (MJMC)
              </option>
              <option>Master of Physiotherapy (MPT)</option>
              <option>Master of Fashion Design (MFD)</option>
              <option>Master of Science in Nursing (M.Sc. Nursing)</option>
              <option>Master of Social Work (MSW)</option>
              <option>Master of Ayurvedic Medicine and Surgery (MAMS)</option>
              <option>Master of Occupational Therapy (MOT)</option>
              <option>Master of Business Management (MBM)</option>
              <option>Master of Veterinary Science (MVSc)</option>
              <option>Master of Event Management (MEM)</option>
              <option>Master of Animation (M.Anim)</option>
            </select>
          </label>
          <label>Stream: </label>
          <input
            type="text"
            name="stream"
            value={formData.stream}
            onChange={handleChange}
          />

          <label> Subject Name</label>

          <input
            type="text"
            name="subject"
            value={formData.subject}
            onChange={handleChange}
          />
          <label>Table Of Content</label>
          {formItems.map((item, index) => (
            <div key={index} id="toc">
              <input
                type="text"
                placeholder="Title"
                value={item.title}
                onChange={(e) =>
                  handleInputChange(index, "title", e.target.value)
                }
              />
              <input
                type="text"
                placeholder="Description"
                value={item.content}
                onChange={(e) =>
                  handleInputChange(index, "content", e.target.value)
                }
              />
              <select
                value={item.contentType}
                onChange={(e) =>
                  handleInputChange(index, "contentType", e.target.value)
                }
              >
                <option value="read">text</option>
                <option>video</option>
              </select>

              <input
                type="text"
                placeholder="contentUrl"
                value={item.contentUrl}
                onChange={(e) =>
                  handleInputChange(index, "contentUrl", e.target.value)
                }
              />

              <button id="removeItem" onClick={() => handleRemoveItem(index)}>
                Remove
              </button>
            </div>
          ))}
          <button onClick={handleAddItem}>Add New Item</button>
          <button type="submit" onClick={handleSubmit}>
            Create{" "}
          </button>
        </div>
      </div>
    </>
  );
};

export default Hackathons;
