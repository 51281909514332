import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { FormLabel } from "react-bootstrap";

const Login = (prop) => {
  const navigate = useNavigate();
  const [passwordShown, setPasswordShown] = useState(false);
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({});
  const userToken = localStorage.getItem("userToken");

  useEffect(() => {
    prop.setShow(false);
    if (userToken) {
      navigate("/dashboard/kpis");
    }
  }, []);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    let data = JSON.stringify({
      email: formData.email,
      password: formData.password,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_LOGIN,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        if (response.data.status == true) {
          navigate("/dashboard/kpis");
          localStorage.setItem("userToken", response.data.userToken);
        }
      })
      .catch((error) => {});
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <>
      <div className="login">
        <div className="loginForm">
          <h1>multigrad</h1>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
            className="signupHomepage"
          >
            <Form.Group controlId="validationCustom03">
              <FormLabel>Username or Email Address</FormLabel>
              <Form.Control
                type="text"
                placeholder="Username or Email Address"
                name="email"
                required
                value={formData.email}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="validationCustom03">
              <FormLabel>Username or Email Address</FormLabel>
              <span>
                <Form.Control
                  placeholder="password"
                  name="password"
                  required
                  value={formData.password}
                  onChange={handleChange}
                  type={passwordShown ? "text" : "password"}
                  className="rg-form"
                />

                <span onClick={togglePasswordVisiblity}>
                  {passwordShown == true && (
                    <AiOutlineEyeInvisible className="eyeviewer">
                      {" "}
                    </AiOutlineEyeInvisible>
                  )}
                  {passwordShown == false && (
                    <AiOutlineEye className="eyeviewer"></AiOutlineEye>
                  )}
                </span>
              </span>
            </Form.Group>
            <br />
            <Button type="submit">Login</Button>
          </Form>
        </div>
      </div>
    </>
  );
};

export default Login;
