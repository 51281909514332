import React, { useEffect, useState } from "react";
import axios from "axios";
import CanvasJSReact from "@canvasjs/react-charts";
import {
  BsGooglePlay,
  BsFillPieChartFill,
  BsBarChartLine,
} from "react-icons/bs";
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const ApiDataComponent = () => {
  const [apiData, setApiData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const userToken = localStorage.getItem("userToken");

      try {
        const response = await axios.get(
          "https://api.multigrad.in/auth/panel/kpis",
          {
            headers: {
              Authorization: userToken,
            },
            maxBodyLength: Infinity,
          }
        );

        setApiData(response.data);
      } catch (error) {}
    };

    fetchData();
  }, []);

  const renderCharts = () => {
    if (!apiData) return null;

    const userTypeDataPoints = Object.keys(apiData.userTypeCounts).map(
      (userType) => ({
        y: apiData.userTypeCounts[userType],
        label: userType === "" ? "Unknown" : userType.trim(),
      })
    );

    const campusDataPoints = Object.keys(apiData.campusCounts).map(
      (campus) => ({
        y: apiData.campusCounts[campus],
        label: campus === "" ? "Unknown" : campus.trim(),
      })
    );

    const classOrCourseDataPoints = Object.keys(
      apiData.classOrCourseCounts
    ).map((classOrCourse) => ({
      y: apiData.classOrCourseCounts[classOrCourse],
      label: classOrCourse === "" ? "Unknown" : classOrCourse.trim(),
    }));

    const roadMapNameDataPoints = Object.keys(apiData.roadMapNameCounts).map(
      (roadMapName) => ({
        y: apiData.roadMapNameCounts[roadMapName],
        label: roadMapName === "null" ? "Unknown" : roadMapName.trim(),
      })
    );
    userTypeDataPoints.sort((a, b) => b.y - a.y);
    const top7DataPoints = userTypeDataPoints.slice(1, 8);

    const optionsUserType = {
      animationEnabled: true,
      data: [
        {
          type: "column",
          indexLabel: "{label}: {y}",
          dataPoints: top7DataPoints,
        },
      ],
    };

    const optionsCampus = {
      animationEnabled: true,
      subtitles: [
        {
          text: "71% Positive",
          verticalAlign: "center",
          fontSize: 1,
          dockInsidePlotArea: true,
        },
      ],
      data: [
        {
          type: "doughnut",
          indexLabel: "{label}: {y}",
          dataPoints: campusDataPoints,
        },
      ],
    };

    const optionsClassOrCourse = {
      animationEnabled: true,
      data: [
        {
          type: "pie",
          indexLabel: "{label}: {y}",
          dataPoints: classOrCourseDataPoints,
        },
      ],
    };

    const optionsRoadMapName = {
      animationEnabled: true,

      data: [
        {
          type: "pie",
          indexLabel: "{label}: {y}",
          dataPoints: roadMapNameDataPoints,
        },
      ],
    };

    return (
      <>
        <div className="rs_in_first">
          <div className="rs_in graph">
            <h6>
              <span id="divUsers">
                <BsBarChartLine />
              </span>
              Users by Roadmap
            </h6>
            <CanvasJSChart id="graph" options={optionsUserType} />
          </div>
        </div>
        <div className="rs_in_first">
          <div className="rs_in graph">
            <h6>
              <span id="divUsers">
                <BsBarChartLine />
              </span>
              Users By Course
            </h6>
            <CanvasJSChart id="graph" options={optionsClassOrCourse} />
          </div>
        </div>

        <div className="rs_in_first">
          <div className="rs_in graph">
            <h6>
              <span id="divUsers">
                <BsBarChartLine />
              </span>
              Users By Campus
            </h6>
            <CanvasJSChart id="graph" options={optionsCampus} />
          </div>
        </div>

        <div className="rs_in_first">
          <div className="rs_in graph">
            <h6>
              <span id="divUsers">
                <BsBarChartLine />
              </span>
              Users by Roadmap
            </h6>
            <CanvasJSChart id="graph" options={optionsRoadMapName} />
          </div>
        </div>
      </>

      // <div className="graphs">
      //   <div className="graph">

      //   </div>
      //   <div className="graph">
      //     <CanvasJSChart id="graph" options={optionsCampus} />
      //   </div>
      //   <div className="graph">
      //     <CanvasJSChart id="graph" options={optionsClassOrCourse} />
      //   </div>
      //   <div className="graph">
      //     <CanvasJSChart id="graph" options={optionsRoadMapName} />
      //   </div>
      // </div>
    );
  };

  return <div className="right_sidebar">{renderCharts()}</div>;
};

export default ApiDataComponent;
