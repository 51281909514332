import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

const Paid = (prop) => {
    // paidShow,setPaidShow

    const handleClose=()=>{
        prop.setPaidShow(false)
    }
  return (
    <Modal
    aria-labelledby="contained-modal-title-vcenter"
    centered
    show={prop.paidShow} onHide={handleClose}>
   
    <Modal.Body>
    <Form>
    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Amount</Form.Label>
              <Form.Control
                type="number"
                placeholder="amount"
                autoFocus
              />
            </Form.Group>
        <br />
        <Button>Pay</Button>
        </Form>
</Modal.Body>

  </Modal>
  )
}

export default Paid