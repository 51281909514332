import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Badge from "react-bootstrap/Badge";
import Table from "react-bootstrap/Table";
import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";
import { AiOutlineClose } from "react-icons/ai";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import Paid from "../modal/Paid";
import Modal from "react-bootstrap/Modal";
const Accounts = () => {
  const userToken = localStorage.getItem("userToken");

  const [users, setUsers] = useState([]);
  const [totalCounts, setTotalCounts] = useState("");
  const [paidShow, setPaidShow] = useState(false);
  const [show, setShow] = useState(false);
  const [userId, serUserId] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const usersPerPage = 10; // Number of users to display per page

  // Calculate the index of the first and last user to be displayed on the current page
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);

  const [userData, setUserData] = useState({
    semester: "",
    fullName: "",
    email: "",
    phone: "",
    campusCode: "",
    subjectTeaches: "",
    classOrCourse: "",
    stream: "",
    password: "",
    userType: "",
    instituteType: "",
    userProfile: "https://api.multigrad.in/avatar/11.png",
    userVerified: "",
    skills: [],
    roadMapName: "",
    userRole: "",
    gender: "",
    userName: "",
    userInstagram: "",
    userLinkedin: "",
    userFacebook: "",
    userWebsite: "",
    userOtherLink: "",
    userTwitter: "",
    gender: "",
  });

  const handleClose = () => setShow(false);
  const handleShow = (items) => {
    serUserId(items._id);
    setShow(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `https://api.multigrad.in/auth/panel/user/${items._id}`,
      headers: {
        authorization: userToken,
        "Content-Type": "application/json",
      },
    };
    axios
      .request(config)
      .then((response) => {
        setUserData({
          ...userData,
          fullName: response.data.data[0].fullName,
          semester: response.data.data[0].semester,
          email: response.data.data[0].email,
          phone: response.data.data[0].phone,
          campusCode: response.data.data[0].campusCode,
          subjectTeaches: response.data.data[0].subjectTeaches,
          classOrCourse: response.data.data[0].classOrCourse,
          stream: response.data.data[0].stream,
          password: response.data.data[0].password,
          userType: response.data.data[0].userType,
          instituteType: response.data.data[0].instituteType,
          userProfile: response.data.data[0].userProfile,
          userVerified: response.data.data[0].userVerified,
          skills: [],
          roadMapName: response.data.data[0].roadMapName,
          userRole: response.data.data[0].userRole,
          gender: response.data.data[0].gender,
          userName: response.data.data[0].userName,
          userInstagram: response.data.data[0].userInstagram,
          userLinkedin: response.data.data[0].userLinkedin,
          userFacebook: response.data.data[0].userFacebook,
          userWebsite: response.data.data[0].userWebsite,
          userOtherLink: response.data.data[0].userOtherLink,
          userTwitter: response.data.data[0].userTwitter,
          gender: response.data.data[0].gender,
        });
      })
      .catch((error) => {});
  };

  const handleSave = () => {
    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `https://api.multigrad.in/auth/panel/user/${userId}`,
      headers: {
        Authorization: userToken,
        "Content-Type": "application/json",
      },
      data: userData,
    };

    axios
      .request(config)
      .then((response) => {
        fetchData();
        Swal.fire({
          icon: "success",
          title: "Successfully Updated",
          showConfirmButton: false,
          timer: 1500,
        });
        setShow(false);
      })
      .catch((error) => {});
  };

  const fetchData = async (action) => {
    try {
      const response = await axios.get(
        "https://api.multigrad.in/auth/panel/total-users",
        {
          headers: {
            Authorization: userToken,
            "Content-Type": "application/json",
          },
        }
      );

      setTotalCounts(response.data.totalCounts);
      setUsers(response.data.data);
    } catch (error) {}
  };

  useEffect(() => {
    fetchData("approve");
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  return (
    // klmkelke
    <>
      {paidShow && <Paid paidShow={paidShow} setPaidShow={setPaidShow} />}
      <div className="right_sidebar">
        <div className="rs_in_third card border-0">
          <Table>
            <thead>
              <tr>
                <th>#</th>
                <th>Profile</th>
                <th>FullName</th>
                <th>Username</th>
                <th>Verification</th>
                <th>Manage</th>
              </tr>
            </thead>
            <tbody>
              {currentUsers.map((user, i) => (
                <tr key={user.id}>
                  <td>{i + 1 + (currentPage - 1) * usersPerPage}</td>
                  <td>
                    <img src={user.userProfile} width="30" />
                  </td>
                  <td>{user.fullName}</td>
                  <td>{user.userName}</td>
                  <td>
                    {user.userVerified == "yes" && (
                      <img
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e4/Twitter_Verified_Badge.svg/800px-Twitter_Verified_Badge.svg.png"
                        width="25px"
                      />
                    )}
                  </td>
                  <td>
                    <button onClick={() => handleShow(user)}>View</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="pages">
            <button onClick={handlePrevPage} disabled={currentPage === 1}>
              Prev
            </button>
            <button
              onClick={handleNextPage}
              disabled={indexOfLastUser >= users.length}
            >
              Next
            </button>
          </div>
        </div>
      </div>
      //modal
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{userData.fullName}'s Details</Modal.Title>
        </Modal.Header>
        <Modal.Body id="table">
          <tr>
            <td>Profile Pic</td>
            <td>
              <img src={userData.userProfile} width="30" />
            </td>
          </tr>
          <tr>
            <td>Profile Pic Url</td>
            <td>
              <input
                type="text"
                placeholder=""
                value={userData.userProfile}
                onChange={handleChange}
                name="userProfile"
                required
              />
            </td>
          </tr>
          <tr>
            <td>Full Name</td>
            <td>
              <input
                type="text"
                placeholder=""
                value={userData.fullName}
                onChange={handleChange}
                name="fullName"
                required
              />
            </td>
          </tr>

          <tr>
            <td>Phone Number</td>
            <td>
              <input
                type="text"
                placeholder=""
                value={userData.phone}
                onChange={handleChange}
                name="phone"
                required
              />
            </td>
          </tr>

          <tr>
            <td>Campus Code</td>
            <td>
              <input
                type="text"
                placeholder=""
                value={userData.campusCode}
                onChange={handleChange}
                name="campusCode"
                required
              />
            </td>
          </tr>

          <tr>
            <td>Class/Course</td>
            <td>
              <input
                type="text"
                placeholder=""
                value={userData.classOrCourse}
                onChange={handleChange}
                name="classOrCourse"
                required
              />
            </td>
          </tr>
          <tr>
            <td>Stream/Branch</td>
            <td>
              <input
                type="text"
                placeholder=""
                value={userData.stream}
                onChange={handleChange}
                name="stream"
                required
              />
            </td>
          </tr>

          <tr>
            <td>Semester</td>
            <td>
              <input
                type="text"
                placeholder=""
                value={userData.semester}
                onChange={handleChange}
                name="semester"
                required
              />
            </td>
          </tr>

          <tr>
            <td>Username</td>
            <td>
              <input
                type="text"
                placeholder=""
                value={userData.userName}
                onChange={handleChange}
                name="userName"
                required
              />
            </td>
          </tr>
          <tr>
            <td>About</td>
            <td>
              <input
                type="text"
                placeholder=""
                value={userData.userType}
                onChange={handleChange}
                name="userType"
                required
              />
            </td>
          </tr>

          <tr>
            <td>Blue Badge</td>
            <td>
              <select
                value={userData.userVerified}
                onChange={handleChange}
                name="userVerified"
              >
                <option>{userData.userVerified}</option>
                <option>no</option>
                <option>yes</option>
              </select>
            </td>
          </tr>
          <tr>
            <td>Instagram</td>
            <td>
              <input
                type="text"
                placeholder=""
                value={userData.userInstagram}
                onChange={handleChange}
                name="userInstagram"
                required
              />
            </td>
          </tr>
          <tr>
            <td>Linkedin</td>
            <td>
              <input
                type="text"
                placeholder=""
                value={userData.userLinkedin}
                onChange={handleChange}
                name="userLinkedin"
                required
              />
            </td>
          </tr>
          <tr>
            <td>Facebook</td>
            <td>
              <input
                type="text"
                placeholder=""
                value={userData.userFacebook}
                onChange={handleChange}
                name="userFacebook"
                required
              />
            </td>
          </tr>
          <tr>
            <td>Website</td>
            <td>
              <input
                type="text"
                placeholder=""
                value={userData.userWebsite}
                onChange={handleChange}
                name="userWebsite"
                required
              />
            </td>
          </tr>
          <tr>
            <td>Other Link</td>
            <td>
              <input
                type="text"
                placeholder=""
                value={userData.userOtherLink}
                onChange={handleChange}
                name="userOtherLink"
                required
              />
            </td>
          </tr>
          <tr>
            <td>Twitter</td>
            <td>
              <input
                type="text"
                placeholder=""
                value={userData.userTwitter}
                onChange={handleChange}
                name="userTwitter"
                required
              />
            </td>
          </tr>
          <tr>
            <td>Gender</td>
            <td>
              <input
                type="text"
                placeholder=""
                value={userData.gender}
                onChange={handleChange}
                name="gender"
                required
              />
            </td>
          </tr>
        </Modal.Body>
        <Modal.Footer>
          <button id="btn" onClick={handleSave}>
            Save Changes
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Accounts;
