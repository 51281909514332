import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";

const Hackathons = () => {
  const userToken = localStorage.getItem("userToken");
  const [formData, setFormData] = useState({
    postQuestion: "",
    postAnswer: "",
    postImage: null,
    postPrivacy: "public",
  });
  const handleChange = (event) => {
    const { name, value, type } = event.target;
    const newValue = type === "file" ? event.target.files[0] : value;
    setFormData((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let data = JSON.stringify({
      hackathonName: formData.hackathonName,
      hackathonTheme: formData.hackathonTheme,
      hackathonMode: formData.hackathonMode,
      hackathonBanner: formData.hackathonBanner,
      hackathonOverview: formData.hackathonOverview,
      hackathonStartTime: formData.hackathonStartTime,
      hackathonEndTime: formData.hackathonEndTime,
      hackathonRegistrationStartTime: formData.hackathonRegistrationStartTime,
      hackathonRegistrationEndTime: formData.hackathonRegistrationEndTime,
      hackathonResult: formData.hackathonResult,
      whoCanParticipate: "Anyone",
      hackathonOrganisersEmail: [
        "amansharma57269@gmail.com",
        "abhishekyadav9672@gmail.com",
      ],
      hackathonRepresentingFrom: formData.hackathonRepresentingFrom,
      hackathonRules: formData.hackathonRules,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://api.multigrad.in/auth/hackathon",
      headers: {
        Authorization: userToken,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Post Created Successfully",
          showConfirmButton: false,
          timer: 1500,
        });
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    // klmkelke
    <>
      <div className="right_sidebar">
        <div className="rs_in_third card border-0">
          <form onSubmit={handleSubmit}>
            <label>Hackathon Name</label>
            <input
              type="text"
              name="hackathonName"
              value={formData.hackathonName}
              onChange={handleChange}
            />
            <label>Hackathon Theme</label>
            <input
              type="text"
              name="hackathonTheme"
              value={formData.hackathonTheme}
              onChange={handleChange}
            />
            <label>Hackathon Mode</label>
            <select>
              <option>virtual</option>
            </select>
            <label>Hackathon Banner</label>
            <input
              type="text"
              name="hackathonBanner"
              value={formData.hackathonBanner}
              onChange={handleChange}
            />
            <label>Hackathon Overview</label>
            <textarea
              type="text"
              name="hackathonOverview"
              value={formData.hackathonOverview}
              onChange={handleChange}
            />
            <label>Hackathon Prizes</label>
            <textarea
              type="text"
              name="hackathonPrizes"
              value={formData.hackathonPrizes}
              onChange={handleChange}
            />
            <label>Hackathon Start Time</label>
            <input
              type="date"
              name="hackathonStartTime"
              value={formData.hackathonStartTime}
              onChange={handleChange}
            />
            <label>Hackathon End Time</label>
            <input
              type="date"
              name="hackathonEndTime"
              value={formData.hackathonEndTime}
              onChange={handleChange}
            />
            <label>Hackathon Registration Time</label>
            <input
              type="date"
              name="hackathonRegistrationStartTime"
              value={formData.hackathonRegistrationStartTime}
              onChange={handleChange}
            />
            <label>Hackathon End Time</label>
            <input
              type="date"
              name="hackathonRegistrationEndTime"
              value={formData.hackathonRegistrationEndTime}
              onChange={handleChange}
            />
            <label>Hackathon Result</label>
            <input
              type="text"
              name="hackathonResult"
              value={formData.hackathonResult}
              onChange={handleChange}
            />
            <label>Hackathon Rules</label>
            <textarea
              type="date"
              name="hackathonRules"
              value={formData.hackathonRules}
              onChange={handleChange}
            />
            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Hackathons;
