import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const CreateJobs = () => {
  const navigate = useNavigate();
  const userToken = localStorage.getItem("userToken");
  const [formData, setFormData] = useState({
    title: "",
    body: "",
  });
  const handleChange = (event) => {
    const { name, value, type } = event.target;
    const newValue = type === "file" ? event.target.files[0] : value;
    setFormData((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let cI = "";

    if (formData.jobOrInternshipImage === "") {
      cI = "https://api.multigrad.in/open/media/?file=1689670312760.png";
    } else {
      cI = formData.jobOrInternshipImage;
    }

    let data = JSON.stringify({
      title: formData.title,
      body: formData.body,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://api.multigrad.in/auth/send-valid-token",
      headers: {
        Authorization: userToken,
        "Content-Type": "application/json",
      },
      data: data,
    };

    // Display loading animation
    const loadingAlert = Swal.fire({
      title: "Please wait...",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      const response = await axios.request(config);

      // Close loading animation
      loadingAlert.close();

      Swal.fire({
        icon: "success",
        title: "Post Created Successfully",
        showConfirmButton: false,
        timer: 1500,
      });

      window.location.reload();
    } catch (error) {
      loadingAlert.close(); // Close loading animation on error as well
    }
  };

  return (
    // klmkelke
    <>
      <div className="right_sidebar">
        <div className="rs_in_third card border-0">
          <form onSubmit={handleSubmit}>
            <label>Title</label>
            <input
              type="text"
              name="title"
              value={formData.title}
              onChange={handleChange}
              required
            />
            <label>Body</label>
            <input
              type="text"
              name="body"
              value={formData.body}
              onChange={handleChange}
              required
            />
            <button type="submit">Send</button>
          </form>
        </div>
      </div>
    </>
  );
};

export default CreateJobs;
