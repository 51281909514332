import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const Hackathons = () => {
  const navigate = useNavigate();
  const userToken = localStorage.getItem("userToken");

  const [formData, setFormData] = useState({
    postQuestion: "",
    postAnswer: "",
    postImage: null,
    postPrivacy: "public",
  });
  const handleChange = (event) => {
    const { name, value, type } = event.target;
    const newValue = type === "file" ? event.target.files[0] : value;
    setFormData((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    let data = JSON.stringify({
      email: formData.email,
      password: formData.password,
      userType: formData.userType,
      fullName: formData.fullName,
      roadMapName: "Backend Developer",
      classOrCourse: formData.classOrCourse,
      stream: formData.stream,
      userRole: formData.userRole,
      userProfile: formData.userProfile,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://api.multigrad.in/secure/registration",
      headers: {
        Authorization: userToken,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Post Created Successfully",
          showConfirmButton: false,
          timer: 1500,
        });
        window.location.reload();
      })
      .catch((error) => {});
  };

  return (
    // klmkelke
    <>
      <div className="right_sidebar">
        <div className="rs_in_third card border-0">
          <form onSubmit={handleSubmit}>
            <label>Email</label>
            <input
              type="text"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <label>Password</label>
            <input
              type="text"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
            />
            <label>Full Name</label>
            <input
              type="text"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              required
            />
            <label>User Role</label>
            <select
              name="userRole"
              value={formData.userRole}
              onChange={handleChange}
              required
            >
              <option value="welcome">admin</option>
              <option value="welcome">user</option>
            </select>

            <label>Class/Course</label>
            <select
              name="classOrCourse"
              value={formData.classOrCourse}
              onChange={handleChange}
              required
            >
              <option value="">Select Degree</option>
              <option value="Bachelor of Animation (B.Anim)">Anyone</option>
              <option value="Bachelor of Technology (B.Tech.)">
                Bachelor of Technology (B.Tech.)
              </option>
              <option value="Bachelor of Medicine, Bachelor of Surgery (MBBS)">
                Bachelor of Medicine, Bachelor of Surgery (MBBS)
              </option>
              <option value="Bachelor of Business Administration (BBA)">
                Bachelor of Business Administration (BBA)
              </option>
              <option value="Bachelor of Commerce (B.Com.)">
                Bachelor of Commerce (B.Com.)
              </option>
              <option value="Bachelor of Science (B.Sc.)">
                Bachelor of Science (B.Sc.)
              </option>
              <option value="Bachelor of Arts (B.A.)">
                Bachelor of Arts (B.A.)
              </option>
              <option value="Bachelor of Pharmacy (B.Pharm)">
                Bachelor of Pharmacy (B.Pharm)
              </option>
              <option value="Bachelor of Design (B.Des.)">
                Bachelor of Design (B.Des.)
              </option>
              <option value="Bachelor of Architecture (B.Arch.)">
                Bachelor of Architecture (B.Arch.)
              </option>
              <option value="Bachelor of Laws (LLB)">
                Bachelor of Laws (LLB)
              </option>
              <option value="Bachelor of Computer Applications (BCA)">
                Bachelor of Computer Applications (BCA)
              </option>
              <option value="Bachelor of Dental Surgery (BDS)">
                Bachelor of Dental Surgery (BDS)
              </option>
              <option value="Bachelor of Fine Arts (BFA)">
                Bachelor of Fine Arts (BFA)
              </option>
              <option value="Bachelor of Education (B.Ed.)">
                Bachelor of Education (B.Ed.)
              </option>
              <option value="Bachelor of Hotel Management (BHM)">
                Bachelor of Hotel Management (BHM)
              </option>
              <option value="Bachelor of Journalism and Mass Communication (BJMC)">
                Bachelor of Journalism and Mass Communication (BJMC)
              </option>
              <option value="Bachelor of Physiotherapy (BPT)">
                Bachelor of Physiotherapy (BPT)
              </option>
              <option value="Bachelor of Fashion Design (BFD)">
                Bachelor of Fashion Design (BFD)
              </option>
              <option value="Bachelor of Science in Nursing (B.Sc. Nursing)">
                Bachelor of Science in Nursing (B.Sc. Nursing)
              </option>
              <option value="Bachelor of Social Work (BSW)">
                Bachelor of Social Work (BSW)
              </option>
              <option value="Bachelor of Ayurvedic Medicine and Surgery (BAMS)">
                Bachelor of Ayurvedic Medicine and Surgery (BAMS)
              </option>
              <option value="Bachelor of Occupational Therapy (BOT)">
                Bachelor of Occupational Therapy (BOT)
              </option>
              <option value="Bachelor of Business Management (BBM)">
                Bachelor of Business Management (BBM)
              </option>
              <option value="Bachelor of Veterinary Science (BVSc)">
                Bachelor of Veterinary Science (BVSc)
              </option>
              <option value="Bachelor of Event Management (BEM)">
                Bachelor of Event Management (BEM)
              </option>
              <option value="Bachelor of Animation (B.Anim)">
                Bachelor of Animation (B.Anim)
              </option>
            </select>
            <label>Stream</label>
            <input
              type="text"
              name="stream"
              value={formData.stream}
              onChange={handleChange}
              required
            />
            <label>Profile Pic Link</label>
            <input
              type="text"
              name="userProfile"
              value={formData.userProfile}
              onChange={handleChange}
              required
            />

            <button type="submit">Create</button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Hackathons;
