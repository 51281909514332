import React, { useEffect, useState } from "react";
import axios from "axios";

import { Link, Route, Routes, useNavigate } from "react-router-dom";
import Paid from "../modal/Paid";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
const UserPost = () => {
  const [users, setUsers] = useState([]);
  const [totalCounts, setTotalCounts] = useState("");
  const [paidShow, setPaidShow] = useState(false);
  const [status, setStatus] = useState();
  const [show, setShow] = useState(false);
  const [userId, serUserId] = useState(false);
  const [messages, setMessages] = useState([]);

  const [userData, setUserData] = useState({
    semester: "",
    fullName: "",
  });
  const handleClose = () => setShow(false);
  const handleShow = (items) => {
    serUserId(items._id);
    setShow(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `https://api.multigrad.in/auth/chat/${items._id}`,
      headers: {
        authorization: userToken,
        "Content-Type": "application/json",
      },
    };
    axios
      .request(config)
      .then((response) => {
        setMessages(response.data.data);
      })
      .catch((error) => {});
  };
  const navigate = useNavigate();
  const userToken = localStorage.getItem("userToken");
  const handleDelete = (item) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this item?"
    );

    if (!confirmed) {
      return;
    }
    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `https://api.multigrad.in/auth/community/${item}`,
      headers: {
        Authorization: userToken,
      },
    };

    axios
      .request(config)
      .then((response) => {
        fetchData();
      })
      .catch((error) => {
        // Handle error scenarios, if needed
      });
  };

  const fetchData = async (action) => {
    try {
      const response = await axios.get(
        "https://api.multigrad.in/auth/community",
        {
          headers: {
            Authorization: userToken,
            "Content-Type": "application/json",
          },
        }
      );

      setTotalCounts(response.data.totalCounts);
      setUsers(response.data.data);
    } catch (error) {}
  };

  useEffect(() => {
    fetchData("approve");
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const usersPerPage = 10; // Number of users to display per page

  // Calculate the index of the first and last user to be displayed on the current page
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);

  const handleNextPage = () => {
    // Go to the next page
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    // Go to the previous page
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleCmt = (userId, question) => {
    navigate(`/comment-page/${userId}/${question}`);
  };

  return (
    // klmkelke
    <>
      {paidShow && <Paid paidShow={paidShow} setPaidShow={setPaidShow} />}
      <div className="right_sidebar">
        <div className="rs_in_third card border-0">
          <h2 id="total">Total Listed Communities {users.length}</h2>
          <table>
            <thead>
              <tr>
                <th>SR No</th>
                <th>Community Name</th>
                <th>Messages</th>
                <th>Manage</th>
              </tr>
            </thead>
            <tbody>
              {currentUsers.map((user, i) => (
                <tr key={user.id}>
                  <td>{i + 1 + (currentPage - 1) * usersPerPage}</td>
                  <td>{user.channelName}</td>
                  <td>
                    <button onClick={() => handleShow(user)}>View</button>
                  </td>

                  <td>
                    <button onClick={() => handleDelete(user.id)}>
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="pages">
            <button onClick={handlePrevPage} disabled={currentPage === 1}>
              Prev
            </button>
            <button
              onClick={handleNextPage}
              disabled={indexOfLastUser >= users.length}
            >
              Next
            </button>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>User Messages</Modal.Title>
        </Modal.Header>

        <Modal.Body id="table">
          {messages.map((message) => (
            <tr>
              <td>
                <img
                  src={message.sender.userProfile}
                  width="30"
                  height="30px"
                />
                {` `}
                {message.sender.fullName}
              </td>
              <td>
                <p id="msg">{message.message}</p>
              </td>
            </tr>
          ))}
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default UserPost;
