import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";

const Hackathons = () => {
  const userToken = localStorage.getItem("userToken");
  const [formItems, setFormItems] = useState([
    {
      roadmapStepName: "",
      contentType: "text",
      roadmapClass: "",
      roadmapStatus: "Not Started",
      roadmapUrl: "https://",
    },
  ]);
  const [formData, setFormData] = useState({
    roadmapNameNew: "",
    roadmapSteps: formItems,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    let data = JSON.stringify({
      roadmapNameNew: formData.roadmapNameNew,
      roadmapSteps: formItems,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://api.multigrad.in/auth/roadmap",
      headers: {
        Authorization: userToken,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {})
      .catch((error) => {});
  };

  const handleInputChange = (index, field, value) => {
    const updatedFormItems = [...formItems];
    updatedFormItems[index][field] = value;
    setFormItems(updatedFormItems);
  };

  const handleAddItem = () => {
    setFormItems([
      ...formItems,
      {
        roadmapStepName: "",
        contentType: "",
        roadmapClass: "",
        roadmapStatus: "Not Started",
        roadmapUrl: "",
      },
    ]);
  };

  const handleRemoveItem = (index) => {
    const updatedFormItems = formItems.filter((item, i) => i !== index);
    setFormItems(updatedFormItems);
  };

  return (
    // klmkelke
    <>
      <div className="right_sidebar">
        <div className="rs_in_third card border-0">
          <label>Roadmap Name </label>
          <input
            type="text"
            name="roadmapNameNew"
            value={formData.roadmapNameNew}
            onChange={handleChange}
          />
          <label>Steps</label>
          {formItems.map((item, index) => (
            <div key={index} id="toc">
              <input
                type="text"
                placeholder="roadmapStepName"
                value={item.roadmapStepName}
                onChange={(e) =>
                  handleInputChange(index, "roadmapStepName", e.target.value)
                }
              />
              <input
                type="text"
                placeholder="Desc"
                value={item.roadmapClass}
                onChange={(e) =>
                  handleInputChange(index, "roadmapClass", e.target.value)
                }
              />
              <select
                value={item.contentType}
                onChange={(e) =>
                  handleInputChange(index, "contentType", e.target.value)
                }
              >
                <option value="Video">video</option>
                <option value="Text">text</option>
              </select>

              <input
                type="text"
                placeholder="roadmapUrl"
                value={item.roadmapUrl}
                onChange={(e) =>
                  handleInputChange(index, "roadmapUrl", e.target.value)
                }
              />

              <button id="removeItem" onClick={() => handleRemoveItem(index)}>
                Remove
              </button>
            </div>
          ))}
          <button onClick={handleAddItem}>Add New Item</button>
          <button type="submit" onClick={handleSubmit}>
            Create{" "}
          </button>
        </div>
      </div>
    </>
  );
};

export default Hackathons;
