import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
const Hackathons = () => {
  const navigate = useNavigate();
  const userToken = localStorage.getItem("userToken");

  const [formData, setFormData] = useState({
    postQuestion: "",
    postAnswer: "",
    postImage: null,
    postPrivacy: "public",
  });
  const handleChange = (event) => {
    const { name, value, type } = event.target;
    const newValue = type === "file" ? event.target.files[0] : value;
    setFormData((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const data = new FormData();
    data.append("postQuestion", formData.postQuestion);
    data.append("postAnswer", formData.postAnswer);
    data.append("postImage", formData.postImage);
    data.append("postPrivacy", formData.postPrivacy);
    data.append("email", formData.email);

    const config = {
      headers: {
        Authorization: userToken,
      },
    };

    axios
      .post("https://api.multigrad.in/auth/panel/ugc", data, config)
      .then((response) => {
        if (response.data.status === true) {
          Swal.fire({
            icon: "success",
            title: "Post Created Successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          window.location.reload();
        } else {
          Swal.fire({
            icon: "error",
            title: "Email Id not exist",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "failed",
          title: "Email Id not exist",
          showConfirmButton: false,
          timer: 1500,
        });
      });
  };

  return (
    // klmkelke
    <>
      <div className="right_sidebar">
        <div className="rs_in_third card border-0">
          <form onSubmit={handleSubmit}>
            <label>User's Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
            <label>Post Question</label>
            <input
              type="text"
              name="postQuestion"
              value={formData.postQuestion}
              onChange={handleChange}
            />
            <label>Post Answer</label>
            <input
              type="text"
              name="postAnswer"
              value={formData.postAnswer}
              onChange={handleChange}
            />
            <label>Post Image</label>
            <input type="file" name="postImage" onChange={handleChange} />
            <label>Post Privacy</label>
            <select
              name="postPrivacy"
              value={formData.postPrivacy}
              onChange={handleChange}
            >
              <option value="choose">choose</option>
              <option value="circle">circle</option>
              <option value="public">public</option>
            </select>

            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Hackathons;
