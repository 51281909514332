import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const CreateJobs = () => {
  const navigate = useNavigate();
  const userToken = localStorage.getItem("userToken");

  const [formData, setFormData] = useState({
    postQuestion: "",
    postAnswer: "",
    postImage: null,
    postPrivacy: "public",
  });
  const handleChange = (event) => {
    const { name, value, type } = event.target;
    const newValue = type === "file" ? event.target.files[0] : value;
    setFormData((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    let cI = "";

    if (formData.jobOrInternshipImage == "") {
      cI = "https://api.multigrad.in/open/media/?file=1689670312760.png";
    } else {
      cI = formData.jobOrInternshipImage;
    }
    let data = JSON.stringify({
      jobOrInternshipTitle: formData.jobOrInternshipTitle,
      jobOrInternshipDescription: formData.jobOrInternshipDescription,
      jobOrInternshipExperience: formData.jobOrInternshipExperience,
      jobOrInternshipPriority: formData.jobOrInternshipPriority,
      jobOrInternshipCompanyWeb: formData.jobOrInternshipCompanyWeb,
      jobOrInternshipImage: cI,
      jobOrInternshipCompany: formData.jobOrInternshipCompany,
      jobOrInternshipPrivacy: formData.jobOrInternshipPrivacy,
      jobOrInternshipLocation: formData.jobOrInternshipLocation,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://api.multigrad.in/auth/job",
      headers: {
        Authorization: userToken,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Post Created Successfully",
          showConfirmButton: false,
          timer: 1500,
        });
        window.location.reload();
      })
      .catch((error) => {});
  };

  return (
    // klmkelke
    <>
      <div className="right_sidebar">
        <div className="rs_in_third card border-0">
          <form onSubmit={handleSubmit}>
            <label>Job/Internship Title</label>
            <input
              type="text"
              name="jobOrInternshipTitle"
              value={formData.jobOrInternshipTitle}
              onChange={handleChange}
              required
            />
            <label>Banner (link)</label>
            <input
              type="text"
              name="jobOrInternshipImage"
              value={formData.jobOrInternshipImage}
              onChange={handleChange}
              required
            />
            <label>Description</label>
            <input
              type="text"
              name="jobOrInternshipDescription"
              value={formData.jobOrInternshipDescription}
              onChange={handleChange}
              required
            />

            <label>Company Name</label>
            <input
              type="text"
              name="jobOrInternshipCompany"
              value={formData.jobOrInternshipCompany}
              onChange={handleChange}
              required
            />
            <label>Where they can apply (link) </label>
            <input
              type="text"
              name="jobOrInternshipCompanyUrl"
              value={formData.jobOrInternshipCompanyUrl}
              onChange={handleChange}
              required
            />
            <label>Experience</label>
            <input
              type="text"
              name="jobOrInternshipExperience"
              value={formData.jobOrInternshipExperience}
              onChange={handleChange}
              required
            />
            <label>Location</label>
            <input
              type="text"
              name="jobOrInternshipLocation"
              value={formData.jobOrInternshipLocation}
              onChange={handleChange}
              required
            />
            <label>Priority</label>
            <input
              type="text"
              name="jobOrInternshipPriority"
              value={formData.jobOrInternshipPriority}
              onChange={handleChange}
              required
            />
            <label>Who Can Apply?</label>
            <select
              name="jobOrInternshipPrivacy"
              value={formData.jobOrInternshipPrivacy}
              onChange={handleChange}
              required
            >
              <option value="">Select Degree</option>
              <option value="Bachelor of Animation (B.Anim)">Anyone</option>
              <option value="Bachelor of Technology (B.Tech.)">
                Bachelor of Technology (B.Tech.)
              </option>
              <option value="Bachelor of Medicine, Bachelor of Surgery (MBBS)">
                Bachelor of Medicine, Bachelor of Surgery (MBBS)
              </option>
              <option value="Bachelor of Business Administration (BBA)">
                Bachelor of Business Administration (BBA)
              </option>
              <option value="Bachelor of Commerce (B.Com.)">
                Bachelor of Commerce (B.Com.)
              </option>
              <option value="Bachelor of Science (B.Sc.)">
                Bachelor of Science (B.Sc.)
              </option>
              <option value="Bachelor of Arts (B.A.)">
                Bachelor of Arts (B.A.)
              </option>
              <option value="Bachelor of Pharmacy (B.Pharm)">
                Bachelor of Pharmacy (B.Pharm)
              </option>
              <option value="Bachelor of Design (B.Des.)">
                Bachelor of Design (B.Des.)
              </option>
              <option value="Bachelor of Architecture (B.Arch.)">
                Bachelor of Architecture (B.Arch.)
              </option>
              <option value="Bachelor of Laws (LLB)">
                Bachelor of Laws (LLB)
              </option>
              <option value="Bachelor of Computer Applications (BCA)">
                Bachelor of Computer Applications (BCA)
              </option>
              <option value="Bachelor of Dental Surgery (BDS)">
                Bachelor of Dental Surgery (BDS)
              </option>
              <option value="Bachelor of Fine Arts (BFA)">
                Bachelor of Fine Arts (BFA)
              </option>
              <option value="Bachelor of Education (B.Ed.)">
                Bachelor of Education (B.Ed.)
              </option>
              <option value="Bachelor of Hotel Management (BHM)">
                Bachelor of Hotel Management (BHM)
              </option>
              <option value="Bachelor of Journalism and Mass Communication (BJMC)">
                Bachelor of Journalism and Mass Communication (BJMC)
              </option>
              <option value="Bachelor of Physiotherapy (BPT)">
                Bachelor of Physiotherapy (BPT)
              </option>
              <option value="Bachelor of Fashion Design (BFD)">
                Bachelor of Fashion Design (BFD)
              </option>
              <option value="Bachelor of Science in Nursing (B.Sc. Nursing)">
                Bachelor of Science in Nursing (B.Sc. Nursing)
              </option>
              <option value="Bachelor of Social Work (BSW)">
                Bachelor of Social Work (BSW)
              </option>
              <option value="Bachelor of Ayurvedic Medicine and Surgery (BAMS)">
                Bachelor of Ayurvedic Medicine and Surgery (BAMS)
              </option>
              <option value="Bachelor of Occupational Therapy (BOT)">
                Bachelor of Occupational Therapy (BOT)
              </option>
              <option value="Bachelor of Business Management (BBM)">
                Bachelor of Business Management (BBM)
              </option>
              <option value="Bachelor of Veterinary Science (BVSc)">
                Bachelor of Veterinary Science (BVSc)
              </option>
              <option value="Bachelor of Event Management (BEM)">
                Bachelor of Event Management (BEM)
              </option>
              <option value="Bachelor of Animation (B.Anim)">
                Bachelor of Animation (B.Anim)
              </option>
            </select>

            <button type="submit">Create</button>
          </form>
        </div>
      </div>
    </>
  );
};

export default CreateJobs;
