import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Badge from "react-bootstrap/Badge";
import Table from "react-bootstrap/Table";
import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";
import { AiOutlineClose } from "react-icons/ai";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import Paid from "../modal/Paid";

const Hackathons = () => {
  const userToken = localStorage.getItem("userToken");

  const [users, setUsers] = useState([]);
  const [totalCounts, setTotalCounts] = useState("");
  const [paidShow, setPaidShow] = useState(false);
  const [status, setStatus] = useState();
  const navigate = useNavigate();

  const fetchData = async (action) => {
    try {
      const response = await axios.get(
        "https://api.multigrad.in/auth/hackathon",
        {
          headers: {
            Authorization: userToken,
            "Content-Type": "application/json",
          },
        }
      );

      setTotalCounts(response.data.totalCounts);
      setUsers(response.data.data);
    } catch (error) {}
  };

  useEffect(() => {
    fetchData("approve");
  }, []);

  const handleDelete = (item) => {
    // Ask for confirmation before proceeding with delete
    const confirmed = window.confirm(
      "Are you sure you want to delete this item?"
    );

    if (!confirmed) {
      // If user clicked Cancel, exit the function
      return;
    }

    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `https://api.multigrad.in/auth/hackathon/${item}`,
      headers: {
        Authorization: userToken,
      },
    };

    axios
      .request(config)
      .then((response) => {
        fetchData();
      })
      .catch((error) => {
        // Handle error scenarios, if needed
      });
  };

  return (
    // klmkelke
    <>
      {paidShow && <Paid paidShow={paidShow} setPaidShow={setPaidShow} />}
      <div className="right_sidebar">
        <div className="rs_in_third card border-0">
          <h2 id="total">Total Listed Hackathons {users.length}</h2>
          <Table>
            <thead>
              <tr>
                <th>#</th>
                <th>Hackathon Name</th>
                <th>Hackathon Theme</th>
                <th>Total Participants</th>
                <th>Hackathon Submissions</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user, i) => (
                <tr key={user.id}>
                  <td>{i + 1}</td>
                  <td>{user.hackathonTheme}</td>
                  <td>{user.hackathonName}</td>
                  <td>{user.participants.length}</td>

                  <td>
                    <a
                      href="https://docs.google.com/forms/d/1qrp7uP-ve8CEU9_2qshcNZAbUSSX15aBqizyv22e_w4/edit"
                      target="_blank"
                    >
                      Google form
                    </a>
                  </td>
                  <td>
                    <button onClick={() => handleDelete(user._id)}>
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
};

export default Hackathons;
