import { HiUsers, HiOutlineUsers } from "react-icons/hi";
import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import CanvasJSReact from "@canvasjs/react-charts";
import {
  BsGooglePlay,
  BsFillPieChartFill,
  BsBarChartLine,
  BsCurrencyRupee,
  BsSpeedometer2,
} from "react-icons/bs";
import { AiOutlineRise } from "react-icons/ai";

import { TbBrandFirebase } from "react-icons/tb";

const CanvasJSChart = CanvasJSReact.CanvasJSChart;
const DashHome = () => {
  const userToken = localStorage.getItem("userToken");

  const [totalCounts, setTotalCounts] = useState("");
  const [dailyRegistration, setDailyRegistration] = useState("");
  const [users, setUsers] = useState([]);
  const [firebase, setFirebase] = useState("");
  const [show, setShow] = useState(false);
  const [userData, setUserData] = useState({
    title: "Your Journey Begins Here! 🚀",
    body: "Join our circle for unparalleled networking, learning, and career growth – where dreams take flight. 🚀🌐",
  });
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://api.multigrad.in/auth/panel/one-day-users",
          {
            headers: {
              Authorization: userToken,
              "Content-Type": "application/json",
            },
          }
        );

        setTotalCounts(response.data.totalCounts);
        setUsers(response.data.data);
        setFirebase(response.data.ft);

        setDailyRegistration(response.data);
      } catch (error) {}
    };

    fetchData();
  }, []);

  const handleClose = () => setShow(false);
  const handleShow = (items) => {
    setShow(true);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div className="right_sidebar">
      <div className="rs_in_first">
        <div className="rs_in daily">
          <h6>
            <span id="users">
              <HiOutlineUsers />
            </span>
            {"  "} Daily Registration
          </h6>

          <p id="number">+{dailyRegistration.todayCounts} </p>
        </div>

        <div className="rs_in">
          <h6>
            <span id="divUsers">
              <HiUsers />
            </span>
            Total Registration
          </h6>
          <p id="number">{totalCounts}</p>
        </div>

        <div className="rs_in">
          <h6>
            <span id="divUsers">
              <BsFillPieChartFill />
            </span>
            Hackathons Organised
          </h6>
          <p id="number">3</p>
        </div>
      </div>
      <div className="rs_in_first">
        <div className="rs_in">
          <h6>
            <span id="divUsers">
              <BsGooglePlay />
            </span>
            Downloads
          </h6>
          <p id="number">1000+</p>
        </div>

        <div className="rs_in">
          <h6>
            <span id="divUsers">
              <AiOutlineRise />
            </span>
            Conversion
          </h6>
          <p id="number">{((firebase / totalCounts) * 100).toFixed(2)}%</p>
        </div>

        <div className="rs_in">
          <h6>
            <span id="divUsers">
              <BsCurrencyRupee />
            </span>
            Revenue
          </h6>
          <p id="number">0.00</p>
        </div>
        <div className="rs_in">
          <h6>
            <span id="divUsers">
              <BsSpeedometer2 />
            </span>
            DAU
          </h6>
          <p id="number">158+</p>
        </div>
        <div className="rs_in">
          <h6>
            <span id="divUsers">
              <TbBrandFirebase />
            </span>
            Valid FT
          </h6>
          <p id="number">{firebase}+</p>
        </div>
      </div>
      <div className="rs_in_third card border-0">
        <h3>Today's Registration</h3>
        <Table>
          <thead>
            <tr>
              <th>S.N</th>
              <th>Profile</th>
              <th>FullName</th>
              <th>Email Address</th>
              <th>Registration Date</th>
              <th>Notify </th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, i) => (
              <tr key={user.id}>
                <td>{i + 1}</td>
                <td>
                  <img src={user.userProfile} width="30" />
                </td>
                <td>{user.fullName}</td>
                <td>{user.email}</td>
                <td>{moment(user.created_date).format("DD/MM/YYYY")}</td>
                <td>
                  <button onClick={() => handleShow(user)}>View</button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Send Welcome Notification</Modal.Title>
        </Modal.Header>
        <Modal.Body id="table">
          <tr>
            <td>Notification Title</td>
            <td>
              <input
                type="text"
                placeholder=""
                value={userData.title}
                onChange={handleChange}
                name="title"
                required
              />
            </td>
          </tr>
          <tr>
            <td>Notification Body</td>
            <td>
              <input
                type="text"
                placeholder=""
                value={userData.body}
                onChange={handleChange}
                name="body"
                required
              />
            </td>
          </tr>
        </Modal.Body>
        <Modal.Footer>
          <button id="btn">Send notification</button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DashHome;
