import React, { useEffect, useState } from "react";
import DashHome from "./DashHome";
import Users from "../userManagement/Accounts";
import Hackathons from "../hackathons/Hackathons";
import UserPost from "../contentManagement/UserPost";
import Jobs from "../hrPortal/Jobs";
import Post from "../contentManagement/createPost";
import SendMail from "../mailerSystem/sendMail";
import Mailer from "../mailerSystem/sendBuldMail";
import Notifications from "../mailerSystem/sendNotification";
import CreateCourse from "../courses/createCourse";
import CreateUser from "../userManagement/createUser";
import CreateJobs from "../hrPortal/createJobs";
import Courses from "../courses/Courses";
import Reports from "../graphs/Kpis";
import CreateHackathon from "../hackathons/createHackathon";
import CreateRoadmap from "../roadmap/createRoadmap";
import Roadmap from "../roadmap/roadmaps";
import Communities from "../community/index";
import CommentPage from "../contentManagement/comment-page";
import { TbDoorExit } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { ListGroup } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { RxDashboard } from "react-icons/rx";
import axios from "axios";
import Confetti from "react-confetti";
import {
  BsBarChartSteps,
  BsFillFilePostFill,
  BsBarChartFill,
  BsPlusSquareDotted,
  BsActivity,
} from "react-icons/bs";
import { BiTrendingUp } from "react-icons/bi";

import { TbNotes, TbVersionsFilled } from "react-icons/tb";
import { FaUsers } from "react-icons/fa";
import { AiOutlineNotification } from "react-icons/ai";
import { IoCreateOutline, IoTerminal } from "react-icons/io5";
import { HiOutlineMail, HiOutlineOfficeBuilding } from "react-icons/hi";
import { MdGroups2 } from "react-icons/md";

const Dashboard = () => {
  const valueList = localStorage.getItem("listvalue");
  const [settingName, setSettingName] = useState(valueList);
  const [listValue, setListValue] = useState();
  const navigate = useNavigate();

  const [totalCount, setTotalCount] = useState(501);
  const [showConfetti, setShowConfetti] = useState(false);

  useEffect(() => {
    if (totalCount === 500 || totalCount === 1000 || totalCount === 5000) {
      setShowConfetti(true);

      const timer = setTimeout(() => {
        setShowConfetti(false);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [totalCount]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTotalCount((prevCount) => prevCount + 1);
    }, 100);

    return () => clearInterval(interval);
  }, []);

  const listClick = (e) => {
    const value = e.target.value;
    localStorage.setItem("listvalue", value);
    setSettingName(value);
    setListValue(value);
  };

  const userToken = localStorage.getItem("userToken");
  const data = [
    {
      icon: <RxDashboard />,
      name: "Dashboard",
      path: "/dashboard/home",
    },

    {
      icon: <MdGroups2 />,
      name: "Communities",
      path: "/dashboard/communities",
    },
    {
      icon: <BsBarChartFill />,
      name: "Reports",
      path: "/dashboard/Reports",
    },
    {
      icon: <BsPlusSquareDotted />,
      name: "Post",
      path: "/dashboard/create-posts",
    },
    {
      icon: <BsFillFilePostFill />,
      name: "UserPost",
      path: "/dashboard/posts",
    },
    {
      icon: <FaUsers />,
      name: "Users",
      path: "/dashboard/account",
    },
    { icon: <FaUsers />, name: "CreateUser", path: "/dashboard/create-user" },
    {
      icon: <IoTerminal />,
      name: "Hackathons",
      path: "/dashboard/hackathons",
    },

    {
      icon: <IoTerminal />,
      name: "CreateHackathon",
      path: "/dashboard/create-hackathon",
    },

    {
      icon: <HiOutlineOfficeBuilding />,
      name: "Jobs",
      path: "/dashboard/jobs",
    },
    {
      icon: <HiOutlineOfficeBuilding />,
      name: "CreateJobs",
      path: "/dashboard/create-jobs",
    },
    {
      icon: <HiOutlineMail />,
      name: "SendMail",
      path: "/dashboard/mailer",
    },
    {
      icon: <HiOutlineMail />,
      name: "Mailer",
      path: "/dashboard/bulk-mailer",
    },

    {
      icon: <AiOutlineNotification />,
      name: "Notifications",
      path: "/dashboard/notification",
    },
    {
      icon: <TbNotes />,
      name: "Courses",
      path: "/dashboard/courses",
    },
    {
      icon: <TbNotes />,
      name: "CreateCourse",
      path: "/dashboard/create-courses",
    },

    {
      icon: <BsBarChartSteps />,
      name: "Roadmap",
      path: "/dashboard/roadmaps",
    },
    {
      icon: <BsBarChartSteps />,
      name: "CreateRoadmap",
      path: "/dashboard/create-roadmap",
    },
  ];

  useEffect(() => {
    if (localStorage.getItem("username" === null)) {
      navigate("/");
      window.location.reload();
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("userToken");
    localStorage.removeItem("userID");
    navigate("/");
    // window.location.reload();
  };

  // Load the previously selected value from local storage on initial render
  useEffect(() => {
    const savedValue = localStorage.getItem("selectedValue");
    if (savedValue) {
      setListValue(savedValue);
    }
  }, []);
  const [dailyRegistration, setDailyRegistration] = useState("");
  const [appVersion, setAppVersion] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://api.multigrad.in/auth/panel/one-day-users",
          {
            headers: {
              Authorization: userToken,
              "Content-Type": "application/json",
            },
          }
        );
        setDailyRegistration(response.data);
        setAppVersion(response.data.appVersion.appVersion);
        setTotalCount(response.data.totalCounts);
      } catch (error) {}
    };

    fetchData();
  }, []);

  return (
    <>
      <div className="main">
        <div className="left_sidebar">
          <h1 id="logo">multigrad</h1>
          <div className="left_sidebar_text">
            <ListGroup>
              {data.map((value, index) => (
                <NavLink
                  to={value.path}
                  key={index}
                  className="link"
                  activeclassName="active"
                >
                  {value.icon}

                  <option
                    className={
                      listValue === value
                        ? "cursor px-3 py-2  pointer "
                        : "cursor  px-3 py-2 "
                    }
                    value={value.name}
                    key={index.path}
                    onClick={listClick}
                  >
                    {value.name}
                  </option>
                </NavLink>
              ))}
            </ListGroup>
          </div>
          <div className="logout">
            <img src="https://api.multigrad.in/open/media/?file=1691580924735.png" />
            <h1>Multigrad App</h1>
            <h2>Super Admin</h2>
            <p id="logout" onClick={handleLogout}>
              <TbDoorExit /> Logout
            </p>
          </div>
        </div>
        <div className="topbar">
          <div className="leftMenu">
            <h3>Pages / {settingName}</h3>
            <h1>{settingName}</h1>
          </div>

          <div className="leftMenuTwo">
            <h3 id="trending">
              <BiTrendingUp id="trndIco" />+{dailyRegistration.todayCounts}
              {`  `}
              Registration
            </h3>
          </div>
          <div className="leftMenuTwo">
            <h3 id="trending">
              <TbVersionsFilled id="trndIco" />
              {appVersion}
              {`  `}
              App Version
            </h3>
          </div>
        </div>
        <div className="right_sidebar">
          <div
            sm={10}
            className={settingName !== "Dashboard" ? "d-none" : "mx-0"}
          >
            <DashHome settingName={settingName} />
          </div>
          <div sm={10} className={settingName !== "Users" ? "d-none" : "mx-0"}>
            <Users settingName={settingName} />
          </div>
          <div
            sm={10}
            className={settingName !== "Hackathons" ? "d-none" : "mx-0"}
          >
            <Hackathons settingName={Hackathons} />
          </div>
          <div
            sm={10}
            className={settingName !== "UserPost" ? "d-none" : "mx-0"}
          >
            <UserPost settingName={UserPost} />
          </div>
          <div sm={10} className={settingName !== "Jobs" ? "d-none" : "mx-0"}>
            <Jobs settingName={UserPost} />
          </div>
          <div sm={10} className={settingName !== "Post" ? "d-none" : "mx-0"}>
            <Post settingName={Post} />
          </div>
          <div
            sm={10}
            className={settingName !== "CreateHackathon" ? "d-none" : "mx-0"}
          >
            <CreateHackathon settingName={CreateHackathon} />
          </div>

          <div
            sm={10}
            className={settingName !== "SendMail" ? "d-none" : "mx-0"}
          >
            <SendMail settingName={SendMail} />
          </div>

          <div sm={10} className={settingName !== "Mailer" ? "d-none" : "mx-0"}>
            <Mailer settingName={Mailer} />
          </div>
          <div
            sm={10}
            className={settingName !== "CreateCourse" ? "d-none" : "mx-0"}
          >
            <CreateCourse settingName={CreateCourse} />
          </div>
          <div
            sm={10}
            className={settingName !== "CreateUser" ? "d-none" : "mx-0"}
          >
            <CreateUser settingName={CreateUser} />
          </div>
          <div
            sm={10}
            className={settingName !== "CreateJobs" ? "d-none" : "mx-0"}
          >
            <CreateJobs settingName={CreateJobs} />
          </div>
          <div
            sm={10}
            className={settingName !== "Notifications" ? "d-none" : "mx-0"}
          >
            <Notifications settingName={Notifications} />
          </div>
          <div
            sm={10}
            className={settingName !== "Courses" ? "d-none" : "mx-0"}
          >
            <Courses settingName={Courses} />
          </div>
          <div
            sm={10}
            className={settingName !== "CommentPage" ? "d-none" : "mx-0"}
          >
            <CommentPage settingName={CommentPage} />
          </div>
          <div
            sm={10}
            className={settingName !== "Reports" ? "d-none" : "mx-0"}
          >
            <Reports settingName={Reports} />
          </div>

          <div
            sm={10}
            className={settingName !== "CreateRoadmap" ? "d-none" : "mx-0"}
          >
            <CreateRoadmap settingName={CreateRoadmap} />
          </div>
          <div
            sm={10}
            className={settingName !== "Roadmap" ? "d-none" : "mx-0"}
          >
            <Roadmap settingName={Roadmap} />
          </div>
          <div
            sm={10}
            className={settingName !== "Communities" ? "d-none" : "mx-0"}
          >
            <Communities settingName={Communities} />
          </div>

          
        </div>
      </div>
    </>
  );
};

export default Dashboard;
